import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './faq.module.scss'
import Accordion from '../components/accordion'


const FAQ = () => (
  <Layout>
    <SEO title='FAQ' />

    <div className={styles.hero}>
      <div className='container mx-auto text-center py-6 md:py-20'>
        <h1 className='text-white text-xl md:text-4xl'>Knowledge Base</h1>
      </div>
    </div>

    <div className='container mx-auto my-12 px-4 lg:px-8'>
      <h2 className='mt-6 mb-5'>Getting Started</h2>
      <div className='ml-4'>
        <Accordion
          title='How do I reserve my username and sign up?'
          content='If we have reached out directly via email, you can sign up directly with MODE through the link in your inbox. Otherwise, you can sign up through our site and try to secure a spot in our Private Beta.'
        />
        <Accordion
          title='How do I share with my followers?'
          content='If you’re accepted into our Private Beta you’ll be able to invite your followers onto the platform with a special referral link. When someone uses this link, you’ll both get $10 to spend in-app.'
        />
        <Accordion
          title='When is the Private Beta available?'
          content='We’ll have more details about this closer to launch, we’re finalising some details with brands. Keep an eye out for our emails.'
        />
        <Accordion
          title='Why did I get invited?'
          content='We think you would be a great fit for MODE based on your style and how engaged your followers are with what you wear.'
        />
        <Accordion
          title="Why haven't I heard of you before?"
          content='We’ve been quietly working on developing a platform to allow you to earn from some of the best brands. For the time being, we’re keeping it quiet to test our service with some of the best creators out there.'
        />
        <Accordion
          title="I'm having trouble creating an account?"
          content={["Please  ", <Link to='/contact/' className='underline'>contact us</Link>, ", and we’ll get back to you within a few hours."]}
        />
      </div>

      <h2 className='mt-6 mb-5'>Brands</h2>
      <div className='ml-4'>
        <Accordion
          title='How do I get paid?'
          content='You earn when someone makes a purchase inspired by you, whether is the exact item you tag or one that’s recommended by our algorithm. You’ll be notified when someone purchases and automatically receive the commission into your wallet. You can use this to purchase fashion on the platform or withdraw to your bank.'
        />
        <Accordion
          title='What brands are on the platform?'
          content='Currently, due to agreements, we can’t disclose who we work with. Our brands are a mix of top international labels down to small individuals producing unique pieces. No matter your style or favourite brand, you’ll be able to find something you love.'
        />
        <Accordion
          title="A brand isn't on the platform?"
          content="Whilst it’s our goal to have all brands available, sometimes we might not have them onboard. If there’s a brand you think we’re missing: let us know. We always offer similar brands, so if a particular brand isn’t available, there will be countless others to purchase from."
        />
        <Accordion
          title='Can I work with brands?'
          content="At the current time, we don’t offer support to work with a brand - we're figuring out the best way to foster collaboration."
        />
        <Accordion
          title="I'm a brand and want to work with you?"
          content={['Great! We would love to work with you to realise social commerce, you can reach out ', <Link to='/get-in-touch/' className='underline'>here</Link>, '.']}
        />
      </div>

      <h2 className='mt-6 mb-5'>Purchasing and Returns</h2>
      <div className='ml-4'>
        <Accordion
          title='Purchasing'
          content='You can shop hundreds of unique brands through our one checkout. When you purchase an item through MODE, your item will be sent by the brand. Returning items differs currently from brand to brand, please ensure you read the return policy for each brand. You can manage all returns in your order tab.'
        />
        <Accordion
          title='Tracking'
          content="You can track your order through the order tab in MODE, we’ll send you updates along the way too."
        />
        <Accordion
          title='How do I use credits?'
          content='When you have money in your wallet, you can use this to pay for an item in part or full. When you checkout, just select credits to use and they will apply automatically.'
        />
        <Accordion
          title="Why aren't 'Buy Now/Pay Later' services available?"
          content="We’re looking at including these offerings from your favourite lenders soon, we'll notify when these are available."
        />
      </div>

      <h2 className='mt-6 mb-5'>Using the Platform</h2>
      <div className='ml-4'>
        <Accordion
          title='How do I upload?'
          content='Simply select an image or take a photo, our image recognition detects the items in the look to help you match what you’re wearing, to the exact items from brands.'
        />
        <Accordion
          title="A brand isn’t there?"
          content="Sometimes a brand might not be available. If this happens, we'll match it with other similar items from other brands and you'll still earn money if someone buys these."
        />
        <Accordion
          title='How does image recognition work?'
          content="Our software has analysed 1000's of fashion images, we use this comparison to best match the items you wear to the ones in our database."
        />
        <Accordion
          title="My username isn't available?"
          content="Usernames are on a first-come, first-served basis. However, we withhold the right to change usernames at our discretion."
        />
      </div>
    </div>
  </Layout>
)

export default FAQ

