import React, { useState } from 'react';
import chevronDown from '../images/chevron-down.svg';
import styles from './accordion.module.scss';

const Accordion = (props) => {
  const [active,setActive] = useState(false);
  const toggleActive = () => setActive(!active);
  return (
    <div className='mb-3 text-sm'>
      <div className='cursor-pointer flex flex-start' onClick={toggleActive} onKeyDown={toggleActive} aria-hidden='true'>
        <img className='mb-0' src={chevronDown} alt='Chevron Down' />
        <div className='w-full font-bold ml-3'>{props.title}</div>
      </div>

      {
        active
          ? <div className={`mt-3 mb-8 cursor-pointer ${styles.content}`} onClick={toggleActive} onKeyDown={toggleActive} aria-hidden='true'>{props.content}</div>
          : ''
      }
    </div>
  )
}

export default Accordion;
